(function () {
    angular.module('informaApp')
        .component('newFeaturePopup', {
            templateUrl: 'components/new-feature-popup/template.html',
            controller: NewFeaturePopup,
            bindings: {
                isOpen: '<',
                featureList: '<'
            }
        });

    function NewFeaturePopup($element, ModalHelper, NewFeatureCookieService, NavigatorService) {
        this.confirmationDialog = null;

        this.close = (event) => {
            event.stopPropagation();
            ModalHelper.showModalByElement(this.confirmationDialog);
        };

        this.$onChanges = (changes) => {
            if (changes.featureList && this.featureList) {
                this.isOpen = hasNewFeaturesToShow(this.featureList, NewFeatureCookieService);
                this.isDismissed = calculateDismissedStatus(this.isOpen, NewFeatureCookieService);

                NewFeatureCookieService.setLastSeenFeatureIds(this.featureList.map(x => x.id));
            }

            if (this.featureList) {
                $element.find('.new-feature-popup').addClass(`columns-${this.featureList.length}`);
            }
        };

        this.onFeatureCardOpenLinkClick = (feature) => {
            NavigatorService.openFeatureCard(feature.id);
        };

        this.onConfirmationDialogInitialize = (confirmationDialog) => {
            this.confirmationDialog = confirmationDialog;
        };

        this.onConfirmationDialogDismiss = () => {
            this.isDismissed = true;
            NewFeatureCookieService.setDismissedStatus(this.isDismissed);
        };

        this.onConfirmationDialogClose = () => {
            this.isDismissed = true;
        };
    }

    function hasNewFeaturesToShow(features, NewFeatureCookieService) {
        const featureIds = NewFeatureCookieService.getLastSeenFeatureIds();

        return !featureIds || features.length !== featureIds.length || features.some(x => featureIds.every(id => id !== x.id));
    }
    
    function calculateDismissedStatus(hasNewFeatures, NewFeatureCookieService) {
        if (hasNewFeatures) {
            NewFeatureCookieService.setDismissedStatus(false);
        }

        return NewFeatureCookieService.getDismissedStatus();
    }
})();
